/* eslint-disable @next/next/no-img-element */
'use client'
import { format } from 'date-fns'
import html2canvas from 'html2canvas'
import { ChevronLeftIcon } from 'lucide-react'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import {
  ImageDownloadIcon,
  PolarAreaChart,
  PolarAreaVariants,
  ShareIcon,
} from '@/components/shared'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Typography } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { useLanguageStore } from '@/providers'
import { Tables } from '@/supabase'

interface ShareDialogProps {
  trigger: React.ReactNode
  record: Tables<'records'>
}

const ShareRecordDialog = ({ trigger, record }: ShareDialogProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const [open, setOpen] = useState(false)
  const { toast } = useToast()
  const date = new Date(record.created_at)
  const formattedDate = format(date, 'do MMM')
  const sherableRef = useRef<HTMLDivElement>(null)
  const isShareSupported = !!navigator?.share && isMobile

  const downloadScreenshot = async () => {
    try {
      if (!sherableRef.current) return
      const lazyElements = document.querySelectorAll('[loading="lazy"]')
      lazyElements.forEach((element) => {
        element.setAttribute('loading', 'eager')
      })
      const canvas = await html2canvas(sherableRef.current)
      const dateForTitle = format(date, 'dd-MM-yyyy')
      const title = `balance-${dateForTitle}`
      if (isShareSupported) {
        canvas.toBlob(async (blob) => {
          if (!blob) return
          const file = new File([blob], `${title}.png`, { type: 'image/png' })
          navigator
            .share({
              files: [file],
            })
            .catch((error) => console.log('Error sharing', error))
        })
      } else {
        const imageData = canvas.toDataURL('image/png')
        const link = document.createElement('a')
        link.href = imageData
        link.download = `${title}.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      toast({
        title:
          dict['share_record_dialog']['error_capturing_downloading_screenshot'],
        variant: 'destructive',
      })
    } finally {
      const lazyElements = document.querySelectorAll('[loading="eager"]')
      lazyElements.forEach((element) => {
        element.setAttribute('loading', 'lazy')
      })
    }
  }

  const buttonTitle = isShareSupported
    ? dict['share_record_dialog']['share']
    : dict['share_record_dialog']['save_as_photo']
  const buttonIcon = isShareSupported ? <ShareIcon /> : <ImageDownloadIcon />

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        className="left-0 top-0 h-[100dvh] w-full max-w-none translate-x-0 translate-y-0 rounded-none border-none bg-white p-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-0 data-[state=closed]:slide-out-to-top-0 data-[state=open]:slide-in-from-left-0 data-[state=open]:slide-in-from-top-0"
        closable={false}
      >
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between px-4 pt-4">
            <ChevronLeftIcon
              size={24}
              onClick={() => setOpen(false)}
              className="cursor-pointer"
            />
            <Typography weight="medium" className="flex-1 text-center">
              {dict['share_record_dialog']['share_performance']}
            </Typography>
            <div className="w-6"></div>
          </div>
          <div className="flex flex-col items-center bg-[#FAFAFB]">
            <div
              ref={sherableRef}
              className="flex w-full max-w-[441px] flex-col gap-6 bg-[#FAFAFB] px-6 pb-4 pt-5"
            >
              <div className="flex items-start justify-between">
                <div className="flex w-full flex-col">
                  <Typography weight="light">
                    {dict['share_record_dialog']['wheel_of_life']}
                  </Typography>
                  <Typography weight="light" variant="muted" size="small">
                    {formattedDate}
                  </Typography>
                </div>
                <img
                  src="/images/balance-logo-with-text-2.png"
                  alt="Balance with logo"
                  width={90}
                  height={26}
                />
              </div>
              <div className="flex flex-col">
                <PolarAreaChart
                  data={record}
                  variant={PolarAreaVariants.WithPoints}
                />
                <Typography size="small" className="text-center text-[#A1A1AA]">
                  www.itsgoodlife.co
                </Typography>
              </div>
            </div>
          </div>
          <div className="self-center">
            <Button
              size="lg"
              className="flex items-center gap-2"
              onClick={downloadScreenshot}
            >
              {buttonIcon}
              {buttonTitle}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ShareRecordDialog
