import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { createFormRecordSlice } from './form-record-slice'
import { createRecordsSlice } from './records-slice'
import { Store } from './type'

export const useStore = create<Store>()(
  immer((...args) => ({
    ...createFormRecordSlice(...args),
    ...createRecordsSlice(...args),
  })),
)
