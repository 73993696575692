import Markdown from 'react-markdown'

import { Typography } from '@/components/ui/typography'
import { useLanguageStore } from '@/providers'

interface BalanceCardInsightV2Props {
  insight: string
}

const BalanceCardInsightV2 = ({ insight }: BalanceCardInsightV2Props) => {
  const dict = useLanguageStore((state) => state.dictionary)
  return (
    <div className="relative flex flex-col items-center gap-6 rounded-[36px] border border-black border-opacity-10 bg-[#D8F7CD] p-6">
      <div className="flex w-full flex-col">
        <Typography weight="light">
          {dict['balance_card_insight_v2'].ai_insights}
        </Typography>
        <Typography weight="light" size="small" variant="muted">
          {dict['balance_card_insight_v2'].suggestions_to_improve}
        </Typography>
      </div>
      <Typography weight="light" size="small">
        <div className="markdown">
          <Markdown>{insight}</Markdown>
        </div>
      </Typography>
    </div>
  )
}

export default BalanceCardInsightV2
