'use client'
import { signOut } from '@/actions'
import { Button } from '@/components/ui/button'

const LogoutButton = ({ title = 'Sign out' }: { title?: string }) => {
  return (
    <form action={signOut}>
      <Button variant="outline" className="w-full">
        {title}
      </Button>
    </form>
  )
}

export default LogoutButton
