'use client'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent,DialogTrigger } from '@/components/ui/dialog'
import { Typography } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { useDeleteRecord } from '@/data'
import { DictionaryType } from '@/locales/dictionaries.types'
import { useLanguageStore } from '@/providers'

interface ConfirmRecordDeleteProps {
  trigger: React.ReactNode
  recordId: string
  onSuccess: () => void
}

const ConfirmRecordDelete = ({
  trigger,
  recordId,
  onSuccess,
}: ConfirmRecordDeleteProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const [open, setOpen] = useState(false)
  const { toast } = useToast()
  const { mutate, isPending } = useDeleteRecord({
    onSuccess: () => {
      onSuccess?.()
      setOpen(false)
      toast({
        title: dict['confirm_record_delete'].review_deleted,
      })
    },
  })
  const onDelete = () => {
    mutate(recordId)
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="max-w-[335px]">
        <div className="flex flex-col gap-4">
          <div>
            <Typography size="large">
              {dict['confirm_record_delete'].delete_review}
            </Typography>
            <Typography variant="muted" weight="light" size="small">
              {dict['confirm_record_delete'].delete_review_message}
            </Typography>
          </div>
          <div className="flex gap-2 self-end">
            <Button
              disabled={isPending}
              size="lg"
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              {dict['confirm_record_delete'].cancel}
            </Button>
            <Button
              disabled={isPending}
              size="lg"
              variant="destructive"
              onClick={onDelete}
            >
              {isPending ? (
                <Loader2 className="animate-spin" />
              ) : (
                dict['confirm_record_delete'].delete
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmRecordDelete
