'use client'
import Image from 'next/image'

import { Button } from '@/components/ui/button'
import { getRedirectUrl } from '@/lib'
import { cn } from '@/lib/utils'
import { browserClient } from '@/supabase'

import { SigninButtonProvider } from './types'

import AppleLogo from '@/assets/icons/apple-logo.svg'
import GoogleLogo from '@/assets/icons/google-logo.svg'

interface SigninButtonProps {
  provider: SigninButtonProvider
}

const LOGO_PROVIDER_MAP: Record<SigninButtonProvider, any> = {
  [SigninButtonProvider.Google]: GoogleLogo,
  [SigninButtonProvider.Apple]: AppleLogo,
}

const TITLE_PROVIDER_MAP: Record<SigninButtonProvider, string> = {
  [SigninButtonProvider.Google]: 'Continue with Google',
  [SigninButtonProvider.Apple]: 'Continue with Apple',
}

const SigninButton = ({ provider }: SigninButtonProps) => {
  const supabase = browserClient()
  const redirectTo = getRedirectUrl()

  const handleGoogleSignin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo,
      },
    })
    if (error) {
      console.error(error)
    }
  }

  const handleSignin = () => {
    switch (provider) {
      case SigninButtonProvider.Google:
        return handleGoogleSignin()
      case SigninButtonProvider.Apple:
        return
      default:
        return
    }
  }

  const title = TITLE_PROVIDER_MAP[provider]
  const logo = LOGO_PROVIDER_MAP[provider]
  return (
    <Button
      size="lg"
      variant="outline"
      className="flex items-center gap-2"
      onClick={handleSignin}
    >
      <div
        className={cn(
          provider === SigninButtonProvider.Google ? 'w-4 h-4' : 'w-5 h-5',
        )}
      >
        <Image priority alt={title} src={logo} />
      </div>
      {title}
    </Button>
  )
}

export default SigninButton
