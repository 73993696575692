'use client'

import { useEffect } from 'react'

import { LOCAL_ONBOARDING_COMPLETED_STORAGE_KEY } from '@/constants'
import { useNotification } from '@/providers/notification'

const LogoutNotifications = () => {
  const { logOut } = useNotification()
  useEffect(() => {
    logOut()
    localStorage.removeItem(LOCAL_ONBOARDING_COMPLETED_STORAGE_KEY)
  }, [logOut])
  return <></>
}

export default LogoutNotifications
