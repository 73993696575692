/* eslint-disable @next/next/no-img-element */
'use client'

import { ClassValue } from 'clsx'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { PropsWithChildren } from 'react'
import { useIsClient, useMediaQuery } from 'usehooks-ts'

import { LanguageOptions } from '@/app/dictionaries'
import {
  ActiveHistoryIcon,
  ActiveHomeIcon,
  HistoryIcon,
  HomeIcon,
  UserCircleActiveIcon,
  UserCircleIcon,
} from '@/components/shared'
import { cn } from '@/lib/utils'
import { routes } from '@/routes'

interface NavigationBarProps {
  className?: ClassValue
}

const NavigationWrapper = ({
  children,
  href,
  active,
}: PropsWithChildren<{ href: string; active: boolean }>) => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isClient = useIsClient()

  const activeEnabled = isMobile && isClient
  const enabledAndActive = activeEnabled && active
  return (
    <Link scroll href={href} className="relative p-3 pt-5">
      {enabledAndActive && (
        <motion.div
          initial={false}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
          layoutId="active"
          className="bottom-nav-active-element absolute left-0 top-0 h-full w-full"
        />
      )}
      <motion.div
        initial={false}
        whileTap={{ scale: 0.9 }}
        className="text-2xl"
      >
        {children}
      </motion.div>
    </Link>
  )
}

export const NavigationBar = ({ className }: NavigationBarProps) => {
  const pathname = usePathname()
  const historyActive = pathname.includes(routes.history)
  const profileActive = pathname.includes(routes.profile)
  const homeActive = !historyActive && !profileActive

  if (pathname.includes(routes.onboarding)) return null

  return (
    <div
      className={cn(
        'bottom-nav shadow-[0_-1px_2px_0px_rgba(16, 24, 40, 0.05)] sticky bottom-0 z-50 flex w-full justify-center gap-10 border-t bg-white px-6',
        className,
      )}
    >
      <NavigationWrapper href={routes.dashboard} active={homeActive}>
        {homeActive ? (
          <ActiveHomeIcon size={24} className="text-primary" />
        ) : (
          <HomeIcon size={24} className="text-black" />
        )}
      </NavigationWrapper>

      <NavigationWrapper href={routes.history} active={historyActive}>
        {historyActive ? (
          <ActiveHistoryIcon size={24} className="text-primary" />
        ) : (
          <HistoryIcon size={24} className="text-black" />
        )}
      </NavigationWrapper>

      <NavigationWrapper href={routes.profile} active={profileActive}>
        {profileActive ? (
          <UserCircleActiveIcon className="text-primary" />
        ) : (
          <UserCircleIcon className="text-black opacity-40" />
        )}
      </NavigationWrapper>
    </div>
  )
}
