import { lifeCategories } from '@/constants'
import { DictionaryType } from '@/locales/dictionaries.types'
import { LifeCategory } from '@/supabase'

export const getTitle = (field: LifeCategory, dict: DictionaryType) => {
  return dict.life_categories[field].title
}

export const getDescription = (field: LifeCategory, dict: DictionaryType) => {
  return dict.life_categories[field].description
}

export const getIsFirstField = (field: LifeCategory) => {
  return lifeCategories.indexOf(field) === 0
}

export const getIsLastField = (
  field: LifeCategory,
  visibleLifeCategories: LifeCategory[],
) => {
  return (
    visibleLifeCategories.indexOf(field) === visibleLifeCategories.length - 1
  )
}
