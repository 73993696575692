'use client'
import { Checkbox } from '@/components/ui/checkbox'
import { Typography } from '@/components/ui/typography'
import { useUpsertCategoryVisibility } from '@/data'
import { useLanguageStore } from '@/providers'
import { LifeCategory, Tables } from '@/supabase'

const LifeCategoryItem = ({
  lifeCategory,
  category,
}: {
  category: LifeCategory
  lifeCategory?: Tables<'category_visibility'>
}) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const title = dict.life_categories[category].title
  const { mutate, isPending } = useUpsertCategoryVisibility()
  const description = dict.life_categories[category].description
  const visible = lifeCategory?.visible ?? true
  const id = lifeCategory?.id

  const handleToggle = () => {
    mutate({ id, category, visible: !visible })
  }

  return (
    <div className="flex w-full gap-2">
      <Checkbox checked={visible} onClick={handleToggle} disabled={isPending} />
      <div className="flex flex-col gap-[6px]">
        <Typography size="small" weight="regular">
          {title}
        </Typography>
        <Typography weight="light" size="small" variant="muted">
          {description}
        </Typography>
      </div>
    </div>
  )
}

export default LifeCategoryItem
