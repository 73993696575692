import { type ImmerStateCreator, RecordsSlice } from './type'

export const createRecordsSlice: ImmerStateCreator<RecordsSlice> = (
  set,
  get,
) => {
  return {
    records: {},
    addRecord: (week, record) => {
      set((state) => {
        state.records[week] = record
      })
    },
    resetRecords: () => {
      set((state) => {
        state.records = {}
      })
    },
  }
}
