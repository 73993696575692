import { format } from 'date-fns'

import { CalendarIcon, TrashIcon } from '@/components/shared'
import { Checkbox } from '@/components/ui/checkbox'
import { Typography } from '@/components/ui/typography'
import { useDeleteActivity, useUpdateActivity } from '@/data'
import { cn } from '@/lib/utils'
import { Tables } from '@/supabase'

interface ActivityItemProps {
  activity: Tables<'activity'>
}

const ActivityItem = ({ activity }: ActivityItemProps) => {
  const { mutate: updateActivity, isPending: isPendingUpdate } =
    useUpdateActivity()
  const { mutate: deleteActivity, isPending: isPendingDelete } =
    useDeleteActivity()
  const handleActivityClick = (activity: Tables<'activity'>) => {
    if (isPendingUpdate) return
    updateActivity({
      id: activity.id,
      completed: !activity.completed,
      record_id: activity.record_id,
    })
  }

  const handleDeleteActivityClick = (activity: Tables<'activity'>) => {
    if (isPendingDelete) return
    deleteActivity({
      activityId: activity.id,
      recordId: activity.record_id ?? '',
    })
  }

  const hasDeadlinePassedWithoutCompletion =
    activity.deadline &&
    activity.completed === false &&
    new Date(activity.deadline) < new Date()

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between gap-1">
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => handleActivityClick(activity)}
        >
          <Checkbox
            checked={activity.completed ?? false}
            disabled={isPendingUpdate}
          />
          <Typography
            weight="light"
            size="small"
            className={cn(activity.completed && 'line-through opacity-50')}
          >
            {activity.task}
          </Typography>
        </div>
        <div className="w-5">
          <TrashIcon
            className="cursor-pointer text-xl text-muted-foreground"
            onClick={() => handleDeleteActivityClick(activity)}
          />
        </div>
      </div>
      {activity.deadline && (
        <div className="flex items-center gap-2">
          <CalendarIcon
            className={cn(
              hasDeadlinePassedWithoutCompletion
                ? 'text-[#DC2626]'
                : 'text-muted-foreground',
            )}
          />
          <Typography
            weight="light"
            size="xsmall"
            variant={hasDeadlinePassedWithoutCompletion ? 'danger' : 'muted'}
          >
            {format(new Date(activity.deadline), 'MMM d, yyyy')}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default ActivityItem
