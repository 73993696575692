'use client'

import { useEffect } from 'react'

import { setLanguageCookie } from '@/actions/'
import { LanguageOptions } from '@/app/dictionaries'

export default function LanguageCookieSetter({
  lang,
}: {
  lang: LanguageOptions
}) {
  useEffect(() => {
    setLanguageCookie(lang)
  }, [lang])

  return null
}
