'use client'

import { useRouter } from 'next/navigation'
import { useEffect } from 'react'

import { LOCAL_ONBOARDING_COMPLETED_STORAGE_KEY } from '@/constants'
import { NotificationStatus, useNotification } from '@/providers/notification'
import { routes } from '@/routes'

const EnableNotificationsRedirect = () => {
  const { status } = useNotification()
  const router = useRouter()
  useEffect(() => {
    const onboardingCompleted = localStorage.getItem(
      LOCAL_ONBOARDING_COMPLETED_STORAGE_KEY,
    )
    if (
      onboardingCompleted === 'true' ||
      status === NotificationStatus.Enabled
    ) {
      return
    } else {
      router.push(routes.enableNotifications)
    }
  }, [router, status])
  return <></>
}

export default EnableNotificationsRedirect
