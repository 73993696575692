'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { CalendarIcon } from '@/components/shared'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Typography } from '@/components/ui/typography'
import { useToast } from '@/components/ui/use-toast'
import { lifeCategories } from '@/constants'
import { useCreateActivity } from '@/data'
import { cn } from '@/lib/utils'
import { useLanguageStore } from '@/providers'
import { LifeCategory } from '@/supabase'

interface CreateActivityFormProps {
  recordId: string
  trigger: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const CreateActivityForm = ({
  trigger,
  recordId,
  open,
  onOpenChange,
}: CreateActivityFormProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const FormSchema = z.object({
    area: z.nativeEnum(LifeCategory),
    task: z.string().min(2, {
      message: dict['create_activity_form'].descriptionError,
    }),
    deadline: z.date().optional(),
  })

  type FormValues = z.infer<typeof FormSchema>

  const { mutate, isPending } = useCreateActivity()
  const { toast } = useToast()
  const dialogContentRef = useRef<HTMLDivElement>(null)
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  })
  const handleCancelClick = () => {
    onOpenChange?.(false)
  }
  const onSubmit = (data: FormValues) => {
    mutate(
      {
        record_id: recordId,
        category: data.area,
        task: data.task,
        deadline: data.deadline?.toISOString() ?? null,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Activity created successfully',
            variant: 'default',
          })
          onOpenChange?.(false)
          form.reset()
        },
      },
    )
  }

  useEffect(() => {
    if (open) {
      form.reset()
    }
  }, [form, open])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent ref={dialogContentRef} className="max-w-[335px]">
        <div className="flex flex-col gap-4">
          <Typography variant="muted" size="small" weight="medium">
            {dict['create_activity_form'].newGoalToImprove}
          </Typography>
          <Form {...form}>
            <form
              className="flex flex-col gap-4"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="area"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {dict['create_activity_form'].areaLabel}{' '}
                      <span className="text-[#DC2626]">*</span>
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder={
                              dict['create_activity_form'].selectAnArea
                            }
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent
                        ref={(ref) => {
                          if (!ref) return
                          ref.ontouchstart = (e) => {
                            e.preventDefault()
                          }
                        }}
                      >
                        {lifeCategories.map((category) => (
                          <SelectItem key={category} value={category}>
                            {dict.life_categories[category].title}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="task"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {dict['create_activity_form'].descriptionLabel}{' '}
                      <span className="text-[#DC2626]">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="deadline"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Set a deadline</FormLabel>
                    <Popover>
                      <PopoverTrigger
                        disabled={!dialogContentRef.current}
                        asChild
                      >
                        <FormControl>
                          <Button
                            disabled={!dialogContentRef.current}
                            variant={'outline'}
                            className={cn(
                              'flex h-10 w-full justify-start gap-2 rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground hover:bg-background hover:text-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            <CalendarIcon className="text-xl text-muted-foreground" />
                            {field.value ? (
                              format(field.value, 'MMM dd, yyyy')
                            ) : (
                              <span>No date</span>
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-auto p-0"
                        align="start"
                        container={dialogContentRef.current}
                      >
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) => date < new Date()}
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleCancelClick}
                  disabled={isPending}
                >
                  {dict['create_activity_form'].cancel}
                </Button>
                <Button type="submit" variant="default" disabled={isPending}>
                  {dict['create_activity_form'].create}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CreateActivityForm
