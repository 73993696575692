'use client'
import { VolumeHighIcon, VolumeSlashIcon } from '@/components/shared'
import { Switch } from '@/components/ui/switch'
import { Typography } from '@/components/ui/typography'
import { useGetAuthUser, useGetSettings, useUpdateSettings } from '@/data'

const SoundSwitch = ({ title = 'Sound' }: { title?: string }) => {
  const { data: settings, isLoading } = useGetSettings()
  const { mutate, isPending } = useUpdateSettings()
  const { data: auth } = useGetAuthUser()
  const userId = auth?.data.user?.id

  const handleCheckedChange = (checked: boolean) => {
    mutate({ userId: userId ?? '', settings: { sound_enabled: checked } })
  }

  const soundEnabled = settings?.sound_enabled ?? true
  const isDisabled = isLoading || isPending || !userId

  return (
    <div className="flex w-full items-center justify-between border-b border-border py-4 first:border-t">
      <div className="flex items-center gap-2">
        {soundEnabled ? (
          <VolumeHighIcon className="text-2xl" />
        ) : (
          <VolumeSlashIcon className="text-2xl" />
        )}
        <Typography size="small" weight="medium">
          {title}
        </Typography>
      </div>
      <Switch
        disabled={isDisabled}
        id="sound"
        checked={soundEnabled}
        onCheckedChange={handleCheckedChange}
      />
    </div>
  )
}

export default SoundSwitch
