'use client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

const formSchema = z.object({
  email: z.string().email({
    message: 'Please enter a valid email address',
  }),
})

type FormValues = z.infer<typeof formSchema>

export const ValidateSubscriptionForm = () => {
  const router = useRouter()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })
  const onSubmit = (_values: FormValues) => {
    router.push('/enable-notifications')
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Gumroad email *</FormLabel>
              <FormDescription>
                We use Gumroad service to validate your subscription.
              </FormDescription>
              <FormControl>
                <Input placeholder="name@gmail.com" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="!mt-10 w-full">
          Validate membership
        </Button>
      </form>
    </Form>
  )
}
