import { LifeCategory } from '@/supabase'

import { type FormRecordSlice,type ImmerStateCreator } from './type'

const DEFAULT_FORM_RECORD_VALUES: FormRecordSlice['values'] = {
  [LifeCategory.Health]: 5,
  [LifeCategory.Growth]: 5,
  [LifeCategory.Career]: 5,
  [LifeCategory.Friends]: 5,
  [LifeCategory.Love]: 5,
  [LifeCategory.Motivation]: 5,
  [LifeCategory.Recreation]: 5,
  [LifeCategory.Environment]: 5,
  [LifeCategory.Finances]: 5,
}

const RECORD_FORM_FIELDS: LifeCategory[] = [
  LifeCategory.Health,
  LifeCategory.Growth,
  LifeCategory.Career,
  LifeCategory.Friends,
  LifeCategory.Love,
  LifeCategory.Motivation,
  LifeCategory.Recreation,
  LifeCategory.Environment,
  LifeCategory.Finances,
]

export const createFormRecordSlice: ImmerStateCreator<FormRecordSlice> = (
  set,
  get,
) => {
  return {
    values: DEFAULT_FORM_RECORD_VALUES,
    currentField: RECORD_FORM_FIELDS[0],
    visibleFields: RECORD_FORM_FIELDS,
    week: 0,
    setValue: (field: LifeCategory, value: number) => {
      set((state) => {
        state.values[field] = value
      })
    },
    setVisibleFields: (fields: LifeCategory[]) => {
      set((state) => {
        state.visibleFields = fields
        state.currentField = fields[0]
      })
    },
    save: () => {
      const values = get().values
      const addRecord = get().addRecord
      addRecord(0, values)
    },
    nextField: () => {
      set((state) => {
        const currentFieldIndex = state.visibleFields.indexOf(
          state.currentField,
        )
        state.currentField =
          state.visibleFields[currentFieldIndex + 1] ?? state.visibleFields[0]
      })
    },
    previousField: () => {
      set((state) => {
        const currentFieldIndex = state.visibleFields.indexOf(
          state.currentField,
        )
        state.currentField =
          state.visibleFields[currentFieldIndex - 1] ??
          state.visibleFields[state.visibleFields.length - 1]
      })
    },
    setWeek: (week: number) => {
      set((state) => {
        state.week = week
      })
    },
    resetForm: () => {
      set((state) => {
        state.values = DEFAULT_FORM_RECORD_VALUES
        state.currentField = state.visibleFields[0]
        state.week = 0
      })
    },
  }
}
