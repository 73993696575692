'use client'
import { format } from 'date-fns'
import { Loader2Icon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Typography } from '@/components/ui/typography'
import { useUpdateRecord } from '@/data'
import { useLanguageStore } from '@/providers'
import { FormRecordValues } from '@/store/type'
import { useStore } from '@/store/use-store'
import { LifeCategory, Tables } from '@/supabase'

import {
  getDescription,
  getIsFirstField,
  getIsLastField,
  getTitle,
} from '../create-record-form/utils'
import SlideRecordValue from '../slide-record-value'

interface UpdateRecordFormContainerProps {
  trigger: React.ReactNode
  record: Tables<'records'>
}
interface UpdateRecordFormProps {
  record: Tables<'records'>
  onSuccess: () => void
}

function isKeyOfFormRecordValues(
  key: string,
  object: FormRecordValues,
): key is keyof FormRecordValues {
  return key in object
}

const UpdateRecordFormContainer = ({
  trigger,
  record,
}: UpdateRecordFormContainerProps) => {
  const [open, setOpen] = useState(false)
  const resetForm = useStore((state) => state.resetForm)
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetForm()
    }
    setOpen(open)
  }
  const handleSuccess = () => {
    setOpen(false)
    resetForm()
  }
  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="max-w-[335px]">
        <UpdateRecordForm record={record} onSuccess={handleSuccess} />
      </DialogContent>
    </Dialog>
  )
}

const UpdateRecordForm = ({ record, onSuccess }: UpdateRecordFormProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const currentField = useStore((state) => state.currentField)
  const values = useStore((state) => state.values)
  const [currentValue, setCurrentValue] = useState(values[currentField] || 0)
  const visibleFields = useStore((state) => state.visibleFields)
  const setVisibleFields = useStore((state) => state.setVisibleFields)
  const setValue = useStore((state) => state.setValue)
  const formattedDate = format(new Date(record.created_at), 'do MMM')
  const previousField = useStore((state) => state.previousField)
  const nextField = useStore((state) => state.nextField)

  const { mutate, isPending } = useUpdateRecord({ onSuccess })

  useEffect(() => {
    const _visibleFields: LifeCategory[] = []
    Object.entries(values).forEach(([key]) => {
      if (isKeyOfFormRecordValues(key, values)) {
        const defaultValue = record[key] ?? 0
        if (defaultValue) {
          setValue(key, defaultValue)
          _visibleFields.push(key)
        }
      }
    })
    setVisibleFields(_visibleFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, setValue, setVisibleFields])

  useEffect(() => {
    const currentFieldValue = values[currentField]
    if (currentFieldValue) {
      setCurrentValue(currentFieldValue)
    }
  }, [currentField, values])

  const handleNext = () => {
    setValue(currentField, currentValue)
    if (isLastField) {
      mutate({
        recordId: record.id,
        visibleFields,
        values: {
          ...values,
          [currentField]: currentValue,
        },
      })
    } else {
      setCurrentValue(0)
      nextField()
    }
  }

  const title = getTitle(currentField, dict)
  const description = getDescription(currentField, dict)
  const isFirstField = getIsFirstField(currentField)
  const isLastField = getIsLastField(currentField, visibleFields)
  const nextDisabled = !currentValue || isPending

  return (
    <>
      <div className="flex flex-col gap-0">
        <Typography weight="light" size="small" variant="muted">
          {formattedDate}
        </Typography>
        <Typography size="large" weight="medium">
          {title}
        </Typography>
        <Typography weight="light" size="small" variant="muted">
          {description}
        </Typography>
      </div>
      <div className="flex flex-col gap-2 pt-[68px]">
        <SlideRecordValue
          id="record-value"
          value={currentValue}
          onChange={setCurrentValue}
          className="justify-center"
        />
        <div className="flex w-full items-center justify-between">
          <Typography weight="light" size="xsmall" variant="muted">
            {dict['update_record_form'].extremely_bad}
          </Typography>
          <Typography weight="light" size="xsmall" variant="muted">
            {dict['update_record_form'].extremely_good}
          </Typography>
        </div>
      </div>
      <div className="flex justify-end gap-3">
        {!isFirstField && (
          <Button size="sm" variant="secondary" onClick={previousField}>
            {dict['update_record_form'].back}
          </Button>
        )}
        <Button size="sm" disabled={nextDisabled} onClick={handleNext}>
          {isLastField && !isPending
            ? dict['update_record_form'].save
            : dict['update_record_form'].continue}
          {isPending && <Loader2Icon className="animate-spin" />}
        </Button>
      </div>
    </>
  )
}

export default UpdateRecordFormContainer
