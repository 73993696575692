import { BalanceCardHeader,PolarAreaChart } from '@/components/shared'
import { Card, CardContent } from '@/components/ui/card'
import { Tables } from '@/supabase'

interface BalanceCardGraphProps {
  data: Tables<'records'>
}

export const BalanceCardGraph = ({ data }: BalanceCardGraphProps) => {
  return (
    <Card>
      <CardContent className="flex min-h-[232px] flex-col gap-4 px-4 py-4">
        <BalanceCardHeader title="Review" description="Life performance" />
        <PolarAreaChart data={data} />
      </CardContent>
    </Card>
  )
}
