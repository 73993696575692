'use client'
import { format, formatDistanceToNow } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import dynamic from 'next/dynamic'
import { useState } from 'react'

import {
  Edit3Icon,
  PolarAreaChart,
  PolarAreaVariants,
  ShareIcon,
  Trash2Icon,
} from '@/components/shared'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Typography } from '@/components/ui/typography'
import { useLanguageStore } from '@/providers'
import { Tables } from '@/supabase'

import ConfirmRecordDelete from '../confirm-record-delete'
import UpdateRecordForm from '../update-record-form'

interface BalanceCardGraphPropsV2 {
  record: Tables<'records'>
  previousRecord?: Tables<'records'>
  onDeleteSuccess?: () => void
}

const ShareRecordDialog = dynamic(() => import('../share-record-dialog'), {
  ssr: false,
})

const BalanceCardGraphV2 = ({
  record,
  previousRecord,
  onDeleteSuccess,
}: BalanceCardGraphPropsV2) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const lang = useLanguageStore((state) => state.language)
  const [showCompare, setShowCompare] = useState(false)
  const date = new Date(record.created_at)
  const previousDate = previousRecord?.created_at
    ? new Date(previousRecord?.created_at)
    : new Date()
  const formattedPreviousDate = format(previousDate, 'do MMM, yyyy')
  const formattedDate =
    lang === 'es'
      ? format(date, 'do MMM', { locale: es })
      : format(date, 'do MMM')
  const locale = lang === 'en' ? enUS : lang === 'es' ? es : enUS
  const dateDiff = formatDistanceToNow(date, {
    addSuffix: true,
    locale: locale,
  })
  const formatDiff =
    dateDiff === dict['balance_card_graph_v2'].less_than_a_minute_ago
      ? dict['balance_card_graph_v2'].recently
      : dateDiff
  const toggleShowCompare = () => setShowCompare((value) => !value)
  const showToggle = !!previousRecord
  const handleDeleteSuccess = () => {
    onDeleteSuccess?.()
  }
  return (
    <div className="relative flex flex-col items-center gap-6 rounded-[36px] border border-black border-opacity-10 p-6">
      <div className="flex w-full flex-col">
        <Typography weight="light">
          {dict['balance_card_graph_v2'].wheel_of_life}
        </Typography>
        <Typography weight="light" variant="muted" size="small">
          {formattedDate} <span className="text-[#A1A1AA]">({formatDiff})</span>
        </Typography>
      </div>
      <div className="absolute right-3 top-3 flex gap-1">
        <ConfirmRecordDelete
          onSuccess={handleDeleteSuccess}
          recordId={record.id}
          trigger={
            <Button size="icon" variant="secondary">
              <Trash2Icon className="text-base text-destructive" />
            </Button>
          }
        />
        <UpdateRecordForm
          trigger={
            <Button size="icon" variant="secondary">
              <Edit3Icon className="text-base text-[#18181B]" />
            </Button>
          }
          record={record}
        />
        <ShareRecordDialog
          record={record}
          trigger={
            <Button size="icon" variant="secondary">
              <ShareIcon className="text-base text-[#18181B]" />
            </Button>
          }
        />
      </div>
      <PolarAreaChart
        data={record}
        variant={PolarAreaVariants.WithPoints}
        previousData={previousRecord}
        showCompare={showCompare}
      />
      {showToggle && (
        <div className="flex flex-col items-center gap-1">
          <div className="flex w-full items-center justify-center gap-2">
            <Checkbox
              checked={showCompare}
              onCheckedChange={toggleShowCompare}
            />
            <Typography weight="light" size="small" className="text-center">
              {dict['balance_card_graph_v2'].compare_previous_review}
            </Typography>
          </div>
          {showCompare && (
            <Typography
              weight="light"
              size="xsmall"
              variant="muted"
              className="text-center"
            >
              {formattedPreviousDate}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default BalanceCardGraphV2
