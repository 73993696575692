'use client'
import { format } from 'date-fns'
import { Loader2 } from 'lucide-react'
import { useRouter } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'
import { Typography } from '@/components/ui/typography'
import { SlideRecordValue } from '@/components/widgets'
import { lifeCategories } from '@/constants'
import {
  useActivitiesByRecordId,
  useAllCategoryVisibility,
  useCreateRecord,
  useGetLastCoupleRecords,
} from '@/data'
import { useLanguageStore } from '@/providers'
import { FormRecordValues } from '@/store/type'
import { useStore } from '@/store/use-store'
import { LifeCategory } from '@/supabase'

import {
  getDescription,
  getIsFirstField,
  getIsLastField,
  getTitle,
} from './utils'

interface CreateRecordFormProps {
  trigger: React.ReactNode
  showWarning?: boolean
}

type CategoryValues = {
  [K in LifeCategory]?: boolean | null
}

interface WarningProps {
  closeDialog: () => void
  openCreateDialog: () => void
}

const Warning = ({ closeDialog, openCreateDialog }: WarningProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  return (
    <>
      <Typography size="large" weight="medium">
        {dict['create_record_form'].recent_review}
      </Typography>
      <Typography size="small" weight="light" variant="muted">
        {dict['create_record_form'].recent_review_description}
      </Typography>
      <div className="flex justify-end gap-3">
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={closeDialog}
        >
          {dict['create_record_form'].back_button}
        </Button>
        <Button type="button" size="sm" onClick={openCreateDialog}>
          {dict['create_record_form'].continue_button}
        </Button>
      </div>
    </>
  )
}

const CreateRecordFormContainer = ({
  trigger,
  showWarning,
}: CreateRecordFormProps) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const [openedWarning, setOpenedWarning] = useState(false)
  const resetForm = useStore((state) => state.resetForm)

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetForm()
      setOpenedWarning(false)
    }
    setOpen(open)
  }

  const handleSuccess = () => {
    setOpen(false)
    resetForm()
    router.refresh()
  }

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="max-w-[335px]">
        {showWarning && !openedWarning ? (
          <Warning
            closeDialog={() => setOpen(false)}
            openCreateDialog={() => {
              setOpenedWarning(true)
            }}
          />
        ) : (
          <CreateRecordForm onSuccess={handleSuccess} />
        )}
      </DialogContent>
    </Dialog>
  )
}

const CreateRecordForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const visibleFields = useStore((state) => state.visibleFields)
  const currentField = useStore((state) => state.currentField)
  const { data: categories, isLoading: isLoadingCategories } =
    useAllCategoryVisibility()
  const { data: lastRecords, isLoading: isLoadingLastRecord } =
    useGetLastCoupleRecords({ limit: 1 })
  const lastRecord = lastRecords?.[0]
  const { data: lastActivities, isLoading: isLoadingLastActivities } =
    useActivitiesByRecordId(lastRecord?.id || '')

  const { mutate, isPending } = useCreateRecord({ onSuccess })

  const values = useStore((state) => state.values)
  const formattedDate = format(new Date(), 'do MMM')

  const [currentValue, setCurrentValue] = useState(values[currentField] || 0)

  const setVisibleFields = useStore((state) => state.setVisibleFields)
  const setValue = useStore((state) => state.setValue)
  const nextField = useStore((state) => state.nextField)
  const previousField = useStore((state) => state.previousField)

  const title = getTitle(currentField, dict)
  const description = getDescription(currentField, dict)
  const isFirstField = getIsFirstField(currentField)
  const isLastField = getIsLastField(currentField, visibleFields)

  const handleValueChange = (value: number) => {
    setCurrentValue(value)
  }

  const handleNext = () => {
    setValue(currentField, currentValue)
    if (isLastField) {
      const formattedLastRecord = Object.entries(lastRecord || {}).reduce(
        (acc, [key, value]) => {
          if (
            typeof value === 'number' &&
            !isNaN(value) &&
            lifeCategories.includes(key as LifeCategory)
          ) {
            acc[key as keyof FormRecordValues] = value
          }
          return acc
        },
        {} as FormRecordValues,
      )
      mutate({
        visibleFields,
        values: {
          ...values,
          [currentField]: currentValue,
        },
        previousRecord: formattedLastRecord,
        previousActivities: lastActivities,
      })
    } else {
      setCurrentValue(0)
      nextField()
    }
  }

  useEffect(() => {
    const currentFieldValue = values[currentField]
    if (currentFieldValue) {
      setCurrentValue(currentFieldValue)
    }
  }, [currentField, values])

  useEffect(() => {
    const map = categories?.reduce((acc, category) => {
      acc[category.category] = category.visible
      return acc
    }, {} as CategoryValues)

    const filteredFieds = lifeCategories.filter((category) => {
      return map?.[category] ?? true
    })

    setVisibleFields(filteredFieds)
  }, [categories, setVisibleFields])

  const nextDisabled = !currentValue || isPending

  if (isLoadingCategories || isLoadingLastRecord || isLoadingLastActivities) {
    return (
      <div className="flex items-center justify-center py-10 ">
        <Loader2 className="h-16 w-16 animate-spin text-primary" />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col gap-0">
        <Typography weight="light" size="small" variant="muted">
          {formattedDate}
        </Typography>
        <Typography size="large" weight="medium">
          {title}
        </Typography>
        <Typography weight="light" size="small" variant="muted">
          {description}
        </Typography>
      </div>
      <div className="flex flex-col gap-2 pt-[68px]">
        <SlideRecordValue
          id="record-value"
          value={currentValue}
          onChange={handleValueChange}
          className="justify-center"
        />
        <div className="flex w-full items-center justify-between">
          <Typography weight="light" size="xsmall" variant="muted">
            {dict['create_record_form'].extremely_bad}
          </Typography>
          <Typography weight="light" size="xsmall" variant="muted">
            {dict['create_record_form'].extremely_good}
          </Typography>
        </div>
      </div>
      <div className="flex justify-end gap-3">
        {!isFirstField && (
          <Button size="sm" variant="secondary" onClick={previousField}>
            {dict['create_record_form'].back_button}
          </Button>
        )}
        <Button size="sm" disabled={nextDisabled} onClick={handleNext}>
          {isLastField && !isPending
            ? dict['create_record_form'].save_button
            : dict['create_record_form'].continue_button_field}
          {isPending && <Loader2 className="animate-spin" />}
        </Button>
      </div>
    </>
  )
}

export default CreateRecordFormContainer
