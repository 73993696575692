'use client'
import { useRouter } from 'next/navigation'
import { type ReactNode } from 'react'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'

interface SigninSheetProps {
  trigger: ReactNode
}

export const SigninSheet = ({ trigger }: SigninSheetProps) => {
  const router = useRouter()
  const handleSignin = () => {
    router.push('/memberships')
  }
  return (
    <Sheet>
      <SheetTrigger asChild>{trigger}</SheetTrigger>
      <SheetContent side="bottom">
        <SheetHeader className="text-left">
          <SheetTitle>Get started</SheetTitle>
        </SheetHeader>
        <div className="flex flex-col w-full mt-4 gap-2 justify-center max-w-sm">
          <Button variant="secondary" onClick={handleSignin}>
            Continue with Google
          </Button>
          <Button variant="secondary" onClick={handleSignin}>
            Continue with Apple
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
