'use client'
import { ClassValue } from 'clsx'

import { Slider } from '@/components/ui/slider'
import { useGetSettings } from '@/data'

interface SlideRecordValueProps {
  id: string
  value: number
  onChange: (value: number) => void
  className?: ClassValue
}

const SlideRecordValue = ({
  onChange,
  className,
  ...props
}: SlideRecordValueProps) => {
  const { data: settings } = useGetSettings()
  const handleChange = (value: number[]) => {
    onChange(value[0])
  }

  const soundEnabled = settings?.sound_enabled ?? false

  return (
    <Slider
      {...props}
      min={1}
      max={10}
      step={1}
      soundEnabled={soundEnabled}
      defaultValue={[props.value ?? 1]}
      value={[props.value]}
      onValueChange={handleChange}
    />
  )
}

export default SlideRecordValue
