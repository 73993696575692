'use client'

import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { routes } from '@/routes'

import { NavigationBar } from '..'

import balanceLogo from '@/assets/branding/balance-logo-with-text.png'
import poweredByGoodLife from '@/assets/branding/powered-by-goodlife.svg'

const LOGO_WIDTH = 170
const LOGO_HEIGHT = 48
const POWERED_BY_GOOD_LIFE_LOGO_HEIGHT = 16

const DesktopHeader = () => {
  const pathname = usePathname()
  if (pathname.includes(routes.onboarding)) return null
  return (
    <div className="sticky left-0 top-0 z-50 mb-4 hidden h-[72px] w-full items-center justify-between bg-white px-4 shadow-sm md:flex">
      <Link href={routes.dashboard}>
        <Image
          src={balanceLogo}
          alt="Balance Logo"
          width={LOGO_WIDTH}
          height={LOGO_HEIGHT}
        />
      </Link>
      <NavigationBar className="max-w-[375px] border-none shadow-none " />
      <Image
        src={poweredByGoodLife}
        alt="Powered by Good Life"
        width={LOGO_WIDTH}
        height={POWERED_BY_GOOD_LIFE_LOGO_HEIGHT}
      />
    </div>
  )
}

export default DesktopHeader
