'use client'
import { useState } from 'react'

import { PlusIcon } from '@/components/shared'
import { Typography } from '@/components/ui/typography'
import { useActivitiesByRecordId } from '@/data'
import { cn } from '@/lib/utils'
import { useLanguageStore } from '@/providers'
import { LifeCategory } from '@/supabase'

import { ActivityItem, CreateActivityForm } from '..'

interface ActivityCardProps {
  recordId: string
}

const ActivityCard = ({ recordId }: ActivityCardProps) => {
  const dict = useLanguageStore((state) => state.dictionary)
  const { data: activities = [] } = useActivitiesByRecordId(recordId)
  const [open, setOpen] = useState(false)

  const mapActivities = activities.reduce(
    (acc, activity) => {
      acc[activity.category as LifeCategory] = [
        ...(acc[activity.category as LifeCategory] || []),
        activity,
      ]
      return acc
    },
    {} as Record<LifeCategory, typeof activities>,
  )

  const keys = Object.keys(mapActivities) as LifeCategory[]
  const sortedKeys = keys.sort((a, b) => a.localeCompare(b))
  return (
    <div className="relative flex flex-col items-center gap-6 rounded-[36px] border border-black border-opacity-10 bg-[#FFFCF7] p-6">
      <div className="flex w-full flex-col">
        <Typography weight="regular">
          {dict['activity_card'].plan_activities}
        </Typography>
        <Typography weight="regular" size="xsmall" variant="muted">
          {dict['activity_card'].to_improve_areas}
        </Typography>
      </div>
      <div
        onClick={() => setOpen(true)}
        className={cn(
          'absolute right-2 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-[#F6ECE0] text-2xl',
          activities.length > 0 ? 'top-2' : 'top-4',
        )}
      >
        <PlusIcon />
      </div>
      <CreateActivityForm
        recordId={recordId}
        open={open}
        onOpenChange={setOpen}
        trigger={
          <div
            className={cn(
              'absolute right-2 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-[#F6ECE0] text-2xl',
              activities.length > 0 ? 'top-2' : 'top-4',
            )}
          >
            <PlusIcon />
          </div>
        }
      />
      {activities.length > 0 && (
        <div className="flex w-full flex-col gap-6">
          {sortedKeys.map((key) => {
            const activities = mapActivities[key]
            return (
              <div key={key} className="flex flex-col gap-3">
                <Typography weight="regular">
                  {dict.life_categories[key].title}
                </Typography>
                {activities.map((activity) => (
                  <ActivityItem key={activity.id} activity={activity} />
                ))}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ActivityCard
