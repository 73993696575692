'use client'

import * as SliderPrimitive from '@radix-ui/react-slider'
import * as React from 'react'

import { cn } from '@/lib/utils'

import { Typography } from './typography'

const Value = ({
  value = 1,
  soundEnabled,
}: {
  value?: number
  soundEnabled: boolean
}) => {
  const valueRef = React.useRef<HTMLDivElement>(null)
  const isLow = value <= 2
  const isHigh = value >= 8
  const isMedium = !isLow && !isHigh
  const [sound, setSound] = React.useState<HTMLAudioElement | null>(null)

  React.useEffect(() => {
    setSound(new Audio('/sounds/roller-switch.mp3'))
  }, [])

  React.useEffect(() => {
    if (sound) {
      sound.volume = 0.1
    }
  }, [sound])

  React.useEffect(() => {
    if (valueRef.current) {
      valueRef.current.style.transform = 'scale(1.35)'
      valueRef.current.style.transition = 'transform 0.3s ease'
      if (soundEnabled) {
        sound?.play()
      }
      setTimeout(() => {
        if (valueRef.current) {
          valueRef.current.style.transform = 'scale(1)'
        }
      }, 300)
    }
  }, [value, sound, soundEnabled])

  return (
    <div
      ref={valueRef}
      className={cn(
        'absolute -top-[64px] left-[calc(50%_-_26px)] flex h-[52px] w-[52px] items-center justify-center rounded-full border border-secondary bg-secondary transition-colors',
        isLow && 'border-[#E4CBB4] bg-[#FFF7E3]',
        isHigh && 'border-[#B4E4D7] bg-[#E3FFE3]',
        isMedium && 'border-secondary bg-secondary',
      )}
    >
      <Typography weight="medium" size="xsmall" className="animate-scale-pulse">
        {Math.round(value)}
      </Typography>
    </div>
  )
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    soundEnabled?: boolean
  }
>(({ className, value, soundEnabled = true, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    value={value}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
      <SliderPrimitive.Range className="absolute h-full bg-primary" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="relative block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
      {(value ?? []).length > 0 && (
        <Value soundEnabled={soundEnabled} value={value?.[0]} />
      )}
    </SliderPrimitive.Thumb>
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
