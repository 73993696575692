import { format } from 'date-fns'

import { PolarAreaChart, PolarAreaVariants } from '@/components/shared'
import { Card, CardContent } from '@/components/ui/card'
import { Typography } from '@/components/ui/typography'
import { DictionaryType } from '@/locales/dictionaries.types'
import { Tables } from '@/supabase'

interface HistoryRecordItemProps {
  record: Tables<'records'>
}

const HistoryRecordItem = ({ record }: HistoryRecordItemProps) => {
  const date = record.created_at
  const formattedDate = format(new Date(date), 'do MMM')
  return (
    <Card className="bg-background p-0">
      <CardContent className="flex w-full flex-col items-center gap-4 p-4 pb-6">
        <PolarAreaChart data={record} variant={PolarAreaVariants.Borderless} />
        <Typography weight="light" size="xsmall" variant="muted">
          {formattedDate}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default HistoryRecordItem
